import '../components/Header'
import Header from '../components/Header';
import '../styles/css/main.css'
import { createContext, useEffect, useState } from 'react';
import axios, { isCancel, AxiosError } from 'axios';
import PropertyModel from '../data/models/property';
import PropertyCard from '../components/PropertyCard';
import Footer from '../components/Footer';
import { Checkbox, LinearProgress, ToggleButtonGroup, ToggleButton, TextField, Pagination } from '@mui/material';
import FeaturesChecks from '../components/FeaturesChecks';
import StatesChecks from '../components/StatesChecks';
import PublicationDateSelector from '../components/PublicationDateSelector';
import FilterModel from '../data/models/filters';
import React from 'react';
import i18next from '../services/i18n'
import { useTranslation } from "react-i18next";
import Config from '../config';
import { Place } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { GoogleMap, Marker, withGoogleMap, Map } from 'react-google-maps';
import { MapContainer } from '../components/Map';
import { useMemo } from 'react';
import MapNew from '../components/MapNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import FilterListIcon from '@mui/icons-material/FilterList';
import Accordion from '@mui/material/AccordionSummary';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';



export default function Properties() {

  const [selectedRootType, setSelectedRootType] = useState("");
  const [selectedPropertyTypeChildren, setSelectedPropertyTypeChildren] = useState([]);
  const { t } = useTranslation();
  const params = useParams("place");
  const [properties, setProperties] = useState([]);
  const [totalProperties, setTotalProperties] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentIndex, setCurrentIndex] = useState(1);

  const [features, setFeatures] = useState([]);
  const [states, setStates] = useState([]);
  const [sort, setSort] = useState("0");
  const [filters, setFilters] = useState(new FilterModel());
  const [markers, setmarkers] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({
    tieneSuperficie: false,
    tieneRooftop: false,
    tieneHab: false,
    tieneBann: false,
    tieneMedioBann: false,
    tieneEstados: false,
  });
  

  const [view, setView] = useState("0");

  const [showFilters, setShowFilter] = useState(true);
  const sort_options = ['Recientes', 'Antiguos', 'Baratos', 'Precio más alto', 'Grandes', 'Pequeños'];

  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  useEffect(() => {

    const filters = localStorage.getItem("filters");
    var filterModel = new FilterModel();
    filterModel = JSON.parse(filters);
    setFilters(filterModel);
    setSort(filterModel.SortType.toString());
    setItemsPerPage(filterModel.Limit);
    document.querySelector("#map-view").classList.add('hidden');
    const headers = {
      "content-type": "application/json",
    };

  
    axios.post(Config.apiUrl + 'properties/search', filters, { headers })
      .then(response => {
     
       var result = []
        if(response.data.data == null){
           result = [];
        }
        else{
          result = response.data.data.properties;
        }

        
        var total = 0;
        if(result.length > 0){
          total = response.data.data.properties[0].total;
        }
        setTotalProperties(total);
        setPageCount(Math.ceil(total / itemsPerPage));
        setProperties(result);
        var markers = result.map(p => ({ lat: p.latitude, lng: p.longitude, id: p.id }));
        setmarkers(markers);
        setIsLoading(false);

      });

      fetch(Config.apiUrl + "properties/filters?languageCode=" + i18next.language)
      .then(response => response.json())
      .then(data => {
        var features = [];
        var propertyTypes = data.data.propertyTypes;
    
        if (filterModel.propertyTypes) {
          filterModel.propertyTypes.forEach(pt => {
            var exist = propertyTypes.find(p => p.id === pt);
            if (exist && exist.features) {
              exist.features.forEach(feature => {
                if (!features.some(f => f.id === feature.id)) {
                  features.push(feature);
                }
              });
            }
          });
        }
    
        setFeatures(features);
        setStates(data.data.stateTypes);
    
        const organizedTypes = organizePropertyTypes(propertyTypes);
        setPropertyTypes(organizedTypes);
    
        const propertyTypesId = (JSON.parse(localStorage.getItem('filters')) || {}).propertyTypes || [];
        const selectedRootType = propertyTypesId.length > 0 ? propertyTypesId[0] : null;    
        setSelectedRootType(selectedRootType);
    
        const selectedType = organizedTypes.find(type => type.id === selectedRootType);
        setSelectedPropertyTypeChildren(selectedType && selectedType.children ? selectedType.children : []);
        
        setPropertyDetails({
          tieneSuperficie: selectedType ? selectedType.tieneSuperficie : false,
          tieneRooftop: selectedType ? selectedType.tieneRooftop : false,
          tieneHab: selectedType ? selectedType.tieneHab : false,
          tieneBann: selectedType ? selectedType.tieneBann : false,
          tieneMedioBann: selectedType ? selectedType.tieneMedioBann : false,
          tieneEstados: selectedType ? selectedType.tieneEstados : false,
        });
      });    
  }, []);


  const handleRootTypeChange = (event) => {
    const selectedId = event.target.value;
    setSelectedRootType(selectedId);

    const selectedType = propertyTypes.find(type => type.id === selectedId);
    console.log('Selected Type:', selectedType);

    setSelectedPropertyTypeChildren(selectedType && selectedType.children ? selectedType.children : []);

    setPropertyDetails({
        tieneSuperficie: selectedType ? selectedType.tieneSuperficie : false,
        tieneRooftop: selectedType ? selectedType.tieneRooftop : false,
        tieneHab: selectedType ? selectedType.tieneHab : false,
        tieneBann: selectedType ? selectedType.tieneBann : false,
        tieneMedioBann: selectedType ? selectedType.tieneMedioBann : false,
        tieneEstados: selectedType ? selectedType.tieneEstados : false,
    });

    const updatedFilters = { ...filters, propertyTypes: selectedType ? [selectedId] : [] };
    setFilters(updatedFilters);

    updateFeaturesBasedOnPropertyTypes([selectedId]);

    applyFilters();
};

  
  
function onChangePropertyType(id, checked) {
  setFilters(prevFilters => {
      const updatedFilters = new Set(prevFilters.propertyTypes);
      const rootTypeId = selectedRootType;

      const updateFiltersRecursively = (typeId, isChecked) => {
          const type = findTypeRecursively(propertyTypes, typeId);

          if (isChecked) {
              updatedFilters.add(typeId);
              if (typeId !== rootTypeId) {
                  updatedFilters.delete(rootTypeId);
              }
              if (type && type.children) {
                  type.children.forEach(child => updateFiltersRecursively(child.id, true));
              }
          } else {
              updatedFilters.delete(typeId);
              if (type && type.children) {
                  type.children.forEach(child => updateFiltersRecursively(child.id, false));
              }
          }

          if (type && type.nivelSuperiorId && type.nivelSuperiorId !== rootTypeId) {
              const parentType = findTypeRecursively(propertyTypes, type.nivelSuperiorId);
              if (parentType) {
                  const allChildrenSelected = parentType.children.every(child => updatedFilters.has(child.id));
                  if (allChildrenSelected) {
                      updatedFilters.add(parentType.id);
                  } else {
                      updatedFilters.delete(parentType.id);
                  }
              }
          }
      };

      updateFiltersRecursively(id, checked);

      const hasSelectedSubtypes = Array.from(updatedFilters).some(typeId => {
          const type = findTypeRecursively(propertyTypes, typeId);
          return type && isDescendantOf(type, rootTypeId);
      });

      if (!hasSelectedSubtypes) {
          updatedFilters.add(rootTypeId);
      }

      updateFeaturesBasedOnPropertyTypes(Array.from(updatedFilters).concat(rootTypeId));

      return { ...prevFilters, propertyTypes: Array.from(updatedFilters) };
  });

  applyFilters();
}

  
  // Función auxiliar para verificar si un tipo es descendiente del tipo raíz
  function isDescendantOf(type, rootTypeId) {
    while (type && type.nivelSuperiorId) {
      if (type.nivelSuperiorId === rootTypeId) {
        return true;
      }
      type = findTypeRecursively(propertyTypes, type.nivelSuperiorId);
    }
    return false;
  }
  
  
  
  function findTypeRecursively(types, id) {
    for (let type of types) {
      if (type.id === id) return type;
      if (type.children) {
        const found = findTypeRecursively(type.children, id);
        if (found) return found;
      }
    }
    return null;
  }


  const applyFilters = async (fromIndex = 0, page = 1) => {
    filters.From = fromIndex;
    setCurrentIndex(page);
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${Config.apiUrl}properties/search`, 
        filters, 
        { headers: { "content-type": "application/json" } }
      );

      const responseData = response.data;
      if (!responseData || !responseData.data || !Array.isArray(responseData.data.properties)) {
        console.error("Invalid data structure received:", responseData);
        setProperties([]);
        setTotalProperties(0);
        setPageCount(0);
        return;
      }

      const properties = responseData.data.properties;
      const result = properties.map(p => new PropertyModel(
        p.id, p.name, p.price, p.rooms, p.m2, p.description, 
        p.isFavourited, p.features, p.images
      ));

      const total = properties.length > 0 ? properties[0].total : 0;
      setTotalProperties(total);
      setPageCount(Math.ceil(total / itemsPerPage));
      setProperties(result);

      const jsonFilters = JSON.stringify(filters);
      localStorage.setItem("filters", jsonFilters);
    } catch (error) {
      console.error('Error applying filters:', error);
      setProperties([]);
      setTotalProperties(0);
      setPageCount(0);
    } finally {
      setIsLoading(false);
    }
  };
  

  function updateFeaturesBasedOnPropertyTypes(selectedPropertyTypes) {
    const updatedFeatures = [];
    selectedPropertyTypes.forEach(pt => {
        const propertyType = propertyTypes.find(type => type.id === pt);
        if (propertyType && propertyType.features.length > 0) {
            propertyType.features.forEach(feature => {
                if (!updatedFeatures.some(f => f.id === feature.id)) {
                    updatedFeatures.push(feature);
                }
            });
        }
    });
    setFeatures(updatedFeatures.length > 0 ? updatedFeatures : []);
}


  function organizePropertyTypes(propertyTypes) {
    const rootTypes = propertyTypes.filter(type => type.nivel === 0);

    const organizeHierarchy = (types, parentId) => {
      const children = propertyTypes.filter(type => type.nivelSuperiorId === parentId);

      children.forEach(child => {
        const grandchildren = organizeHierarchy(propertyTypes, child.id);
        if (grandchildren.length > 0) {
          child.children = grandchildren;
        }
      });

      return children;
    };

    rootTypes.forEach(rootType => {
      rootType.children = organizeHierarchy(propertyTypes, rootType.id);
    });

    return rootTypes;
  }
  
  
  function PropertyTypeCheckbox({ type, onChangePropertyType, filters }) {
    const isChecked = filters.propertyTypes.includes(type.id);
  
    const handleChange = (e) => {
      e.stopPropagation();
      onChangePropertyType(type.id, e.target.checked);
    };
  
    return (
      <div className="property-type-checkbox">
        <div className="form-check d-flex align-items-center">
          <Checkbox
            id={`checkbox-${type.id}`}
            checked={isChecked}
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
            sx={{
              color: "#F65C01",
              '&.Mui-checked': {
                color: "#F65C01",
              },
            }}
          />
          <label
            className="form-check-label"
            htmlFor={`checkbox-${type.id}`}
            onClick={(e) => e.stopPropagation()}
          >
            {type.nombre}
          </label>
          {type.children && type.children.length > 0 && (
            <button
              className="btn btn-link p-0 ms-2"
              style={{ color: 'black' }}
              onClick={(e) => e.stopPropagation()}
            >
            </button>
          )}
        </div>
        {type.children && type.children.length > 0 && (
          <div className="ms-4 mt-2">
            {type.children.map(childType => (
              <PropertyTypeCheckbox
                key={childType.id}
                type={childType}
                onChangePropertyType={onChangePropertyType}
                filters={filters}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  function getPropertyTypeName(selectedId) {
    const selectedType = propertyTypes.find(type => type.id === selectedId);
    return selectedType ? selectedType.nombre : '';
  }

  function onChangeRooms(value, checked) {
    if (checked) {
      filters.Bedrooms = value;
    }
    else {
      filters.Bedrooms = null;
    }
    applyFilters();
  }
  
  function onChangeBaths(value, checked) {
    //si no hay ninguna marcada se marca >= todas
    if (checked) {
      filters.Baths = value;
    }
    else {
      filters.Baths = null;
    }
    applyFilters();
  }

  function sortProperties(value) {
    setSort(value);
    filters.SortType = Number(value);
    var jsonFilters = JSON.stringify(filters);
    localStorage.setItem("filters", jsonFilters);
    applyFilters();
  }


  function onChangeMinPrice(value) {
    if (value === "")
      filters.MinPrice = null;
    else
      filters.MinPrice = value;
    applyFilters()
  }

  function onChangeMaxPrice(value) {
    if (value === "")
      filters.MaxPrice = null;
    else
      filters.MaxPrice = value;
    applyFilters()
  }

  function onChangeMinSize(value) {
    if (value === "")
      filters.MinSize = null;
    else
      filters.MinSize = value;
    applyFilters()
  }

  function onChangeMaxSize(value) {
    if (value === "")
      filters.MaxSize = null;
    else
      filters.MaxSize = value;
    applyFilters()
  }

  function onChangeOperationType(value) {
    filters.operationType = value;
    applyFilters()
  }

  function changeView(value) {
    setView(value);

    if (value == "0") {
      document.querySelector("#listing-view").classList.remove('hidden');
      document.querySelector("#map-view").classList.add('hidden');
    }

    if (value == "1") {
      document.querySelector("#listing-view").classList.add('hidden');
      document.querySelector("#map-view").classList.remove('hidden');
    }
  }

  const pageChanged = (event, value) => {
    window.scrollTo(0, 0)
    var fromIndex =  (value - 1)*itemsPerPage;
    setCurrentIndex(value);
    applyFilters(fromIndex, value);
  };


  function showHideFilters() {
    setShowFilter(!showFilters);

    var filters = document.getElementsByClassName('filters');

    if (showFilters) {
      filters[0].style.display = 'block'
    }
    else {
      filters[0].style.display = 'none'
    }
  }

  return (
    < >
      <div className='first-container' style={{ marginTop: 20 }}>
        <div className='row'>
          <div className='col-6'>
            <h3><strong>{params.place}: {t('showing')} {properties.length} {t('of')} {totalProperties} {t('posts')}</strong></h3>
          </div>
          <div className='col-6'>
            <div className='put-right'>

              <ToggleButtonGroup onChange={e => changeView(e.target.value)} className='btns-order' value={view} exclusive>
                <ToggleButton value="0">{t('list')}</ToggleButton>
                <ToggleButton value="1">{t('map')}</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>

      </div>
      <div className='row'>
        <div className='col ' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: 5 }}>
            <a onClick={showHideFilters} className='btn-filters'> <FilterListIcon></FilterListIcon> {t('filters')}</a>
          </div>


        </div>

      </div>


      <div className='put-right container'>


        <span style={{ fontSize: 16, paddingRight: 10 }}>{t('sort')}:</span>

        <ToggleButtonGroup className='btns-order'
          value={sort}
          exclusive
          onChange={e => sortProperties(e.target.value)}
        >
          <ToggleButton value="0">{t('recents')}</ToggleButton>
          <ToggleButton value="1">{t('leastrecent')}</ToggleButton>
          <ToggleButton value="2">{t('lowestprice')}</ToggleButton>
          {/* <ToggleButton value="3">{t('highestprice')}</ToggleButton>
            <ToggleButton value="4">{t('biggest')}</ToggleButton>
            <ToggleButton value="5">{t('smallest')}</ToggleButton> */}

        </ToggleButtonGroup>

      </div>

      <div className='row'>

        <div className='col-md-3 col-sm-12'>

          <div className='filters'>

            <label htmlFor="propertyType" className="form-label">{t('propertyop')}:</label>
            <select id="propertyType" className="form-select form-select mb-3" onChange={e => onChangeOperationType(e.target.value)}>
              <option defaultValue={filters.operationType == "AB94A3AB-1F99-43CC-800A-6CF9947981B2"} value="AB94A3AB-1F99-43CC-800A-6CF9947981B2">{t('buy')}</option>
              <option defaultValue={filters.operationType == "2848B78F-0341-4CBB-AD95-0C5408629901"} value="2848B78F-0341-4CBB-AD95-0C5408629901">{t('rent')}</option>
            </select>
            <div id="passwordHelpBlock" className="form-text">

            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <label htmlFor="levelZeroPropertyType" className="form-label">{t('Tipo de inmueble')}:</label>
              <select
                id="levelZeroPropertyType"
                className="form-select form-select mb-3"
                onChange={handleRootTypeChange}
                value={selectedRootType}
              >
                {propertyTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.nombre}
                  </option>
                ))}
              </select>

              {selectedRootType && selectedPropertyTypeChildren.length > 0 && (
                <div>
                  <label htmlFor="levelZeroPropertyType" className="form-label">{t('Tipo de')} {getPropertyTypeName(selectedRootType)}</label>
                  {selectedPropertyTypeChildren.map(childType => (
                    <PropertyTypeCheckbox
                      key={childType.id}
                      type={childType}
                      onChangePropertyType={onChangePropertyType}
                      filters={filters}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="input-group mb-3">
              <label htmlFor="price" className="form-label">{t('price')}:</label>
              <div className='row'>
                <div className='col-6'>
                  <TextField
                    label="Min"
                    InputProps={{ inputProps: { min: 100 } }}
                    type='number'
                    min={1}
                    id="outlined-size-small"
                    size="small"
                    value={filters.MinPrice}
                    onChange={e => onChangeMinPrice(e.target.value)}
                  />
                </div>

                <div className='col-6'>
                  <TextField
                    label="Max"
                    InputProps={{ inputProps: { min: 100 } }}
                    type='number'
                    id="outlined-size-small"
                    defaultValue={filters.MaxPrice}
                    value={filters.MaxPrice}

                    size="small"
                    onChange={e => onChangeMaxPrice(e.target.value)}
                  />
                </div>

              </div>
            </div>

            {propertyDetails.tieneSuperficie && (
            <div className="input-group mb-3">
              <label htmlFor="price" className="form-label">{t('size')}:</label>
              <div className='row'>
                <div className='col-6'>
                  <TextField
                    InputProps={{ inputProps: { min: 1 } }}
                    label="Min"
                    type='number'
                    id="outlined-size-small"
                    defaultValue={filters.MinSize}
                    value={filters.MinSize}
                    size="small"
                    onChange={e => onChangeMinSize(e.target.value)}
                  />
                </div>

                <div className='col-6'>
                  <TextField
                    InputProps={{ inputProps: { min: 1 } }}
                    label="Max"
                    type='number'
                    id="outlined-size-small"
                    defaultValue={filters.MaxSize}
                    value={filters.MaxSize}
                    size="small"
                    onChange={e => onChangeMaxSize(e.target.value)}
                  />
                </div>
              </div>
            </div>
            )}

          
            {features != undefined
            ? <FeaturesChecks features={features} setCurrentIndex={setCurrentIndex} setTotalProperties={setTotalProperties} filters={filters} setFilters={setFilters} setPageCount={setPageCount} itemsPerPage={itemsPerPage} setProperties={setProperties} setIsLoading={setIsLoading}></FeaturesChecks>
            
        : <></>
      }  
          {propertyDetails.tieneEstados && (
            <StatesChecks states={states} setCurrentIndex={setCurrentIndex} filters={filters} setFilters={setFilters} setTotalProperties={setTotalProperties} setPageCount={setPageCount} itemsPerPage={itemsPerPage} setProperties={setProperties} setIsLoading={setIsLoading}></StatesChecks>
          )}
          
          <PublicationDateSelector filters={filters} setFilters={setFilters} setCurrentIndex={setCurrentIndex} setTotalProperties={setTotalProperties} setPageCount={setPageCount} itemsPerPage={itemsPerPage} setProperties={setProperties} setIsLoading={setIsLoading}></PublicationDateSelector>
          
          {propertyDetails.tieneHab && (
            <div className="input-group mb-3">
              <label>{t('rooms')}:</label>

              <div className='row'>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(1, e.target.checked)} checked={filters.Bedrooms === 1}
                    sx={{
                      color: "#F65C01",
                      '&.Mui-checked': {
                        color: "#F65C01",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    1
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(2, e.target.checked)} checked={filters.Bedrooms === 2}
                    sx={{
                      color: "#F65C01",
                      '&.Mui-checked': {
                        color: "#F65C01",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    2
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(3, e.target.checked)} checked={filters.Bedrooms === 3}
                    sx={{
                      color: "#F65C01",
                      '&.Mui-checked': {
                        color: "#F65C01",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    3
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(4, e.target.checked)} checked={filters.Bedrooms >= 4}
                    sx={{
                      color: "#F65C01",
                      '&.Mui-checked': {
                        color: "#F65C01",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    4 habitaciones o más
                  </label>
                </div>
              </div>
            </div>
          )}

          {propertyDetails.tieneBann && (
            <div className="input-group">
              <label>{t('baths')}:</label>

              <div className='row'>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeBaths(1, e.target.checked)} checked={filters.Baths === 1}
                    sx={{
                      color: "#F65C01",
                      '&.Mui-checked': {
                        color: "#F65C01",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    1
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeBaths(2, e.target.checked)} checked={filters.Baths === 2}
                    sx={{
                      color: "#F65C01",
                      '&.Mui-checked': {
                        color: "#F65C01",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    2
                  </label>
                </div>


                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeBaths(3, e.target.checked)} checked={filters.Baths >= 3}
                    sx={{
                      color: "#F65C01",
                      '&.Mui-checked': {
                        color: "#F65C01",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    3 baños o más
                  </label>
                </div>
              </div>

            </div>
            )}
          </div>

        </div>
        <div className='col-md-9 col-sm-12 mb-3'>

          <div id='listing-view'>
            {isLoading ? <LinearProgress /> :
              properties.map(o => (
                <PropertyCard property={o} key={o.id}></PropertyCard>
              ))
            }
            <Pagination page={currentIndex} count={pageCount} onChange={pageChanged} />
          </div>
          <div id='map-view' >
            <MapNew markers={markers}></MapNew>
          </div>
        </div>
      </div>
    </>
  );
}